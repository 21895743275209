<script lang="ts" setup>
import { ParagraphJsScripts } from '~/api/graphql/generated'

const props = defineProps<{
  entity: ParagraphJsScripts
}>()
const text = ref(props.entity.fieldScript?.processed ?? '')

const store = useLoadingStore()
onMounted(() => {
  if (store.isLoading !== null) return window.location.reload() // reload the page in order for the scripts to be executed
})
</script>

<template>
  <Section v-if="text" v-animate="{ animation: 'vertical-reveal' }">
    <div class="t-10 t-o-1 tl-7 tl-o-0 ds-o-1 custom-scripts" v-html="text" />
  </Section>
</template>

<style scoped lang="scss">
.custom-scripts {
  :deep(.one-column-form),
  :deep(.marketingForm) {
    .marketing-field {
      margin-bottom: 0;
    }

    div[data-layout='true'] {
      margin: 0 !important;
    }

    --form-input-padding-block-mobile: 21px;
    --form-input-padding-block: 25px;

    input[type='text'],
    input[type='email'],
    input[type='tel'],
    input[type='number'],
    input[type='password'],
    input[type='date'],
    select,
    textarea {
      appearance: none;
      resize: vertical;

      width: 100%;
      box-shadow: none;

      padding-block: var(--form-input-padding-block-mobile);
      padding-inline: var(--form-input-padding-inline);

      border: 1px solid var(--form-input-border-color);
      outline: 0.5px solid var(--outline-color);
      border-radius: var(--form-input-border-radius);

      @include transitions(border, outline, background, box-shadow);

      @include breakpoint(tl) {
        padding-block: var(--form-input-padding-block);
      }

      background: var(--form-input-background);

      font-family: var(--f-mark);
      font-size: var(--form-input-font-size-mobile);
      line-height: var(--form-input-line-height-mobile);
      color: var(--form-input-color);
      font-weight: 500;

      @include breakpoint(tl) {
        font-size: var(--form-input-font-size);
        line-height: var(--form-input-line-height);
      }

      &::placeholder {
        color: var(--c-blue-60);
      }

      margin-block: 8px;

      &:hover:not(.outer-radio):not(.outer-checkbox) {
        --form-input-border-color: var(--c-blue-100);
        --outline-color: var(--form-input-border-color);

        outline-color: var(--outline-color);
        box-shadow: var(--form-input-box-shadow);
      }
    }

    select {
      padding-right: 60px;
      background-image: url('/img/arrow-down.svg');
      background-position: calc(100% - 20px) 50%;
      background-repeat: no-repeat;
    }

    textarea {
      min-height: 240px;
    }

    input[type='checkbox'] {
      --input-height: 26px;
      --input-width: 26px;
      --input-border: 1px;
      position: relative;
      line-height: 1;
      height: calc(var(--input-height) - var(--input-border) * 2);
      width: 100%;
      min-width: var(--input-width);
      max-width: calc(var(--input-width) - var(--input-border) * 2);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      -webkit-appearance: none;
      appearance: none;
      /* For iOS < 15 to remove gradient background */
      background-color: transparent;
      /* Not removed via appearance */
      margin: 0;
      margin-block: 8px;

      border: 1px solid var(--form-input-border-color);
      // font: inherit;
      // color: currentColor;
      // width: 1.15em;
      // height: 1.15em;
      // border: 0.15em solid currentColor;
      // border-radius: 0.15em;
      // transform: translateY(-0.075em);

      &:before {
        content: '';
        width: 0.65em;
        height: 0.65em;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);
        &:checked {
          transform: scale(1);
        }
      }

      &:checked {
        background: url('/img/check.svg') no-repeat center center;
        background-color: var(--c-primary);
      }

      & ~ label {
        font-family: var(--f-mark);
        font-size: 18px;

        margin: 0;

        a {
          color: var(--c-primary);
          font-weight: inherit;
          font-family: inherit;
          font-size: inherit;
        }
      }
    }

    [data-editorblocktype='Field-checkbox'] {
      .lp-form-field {
        position: relative;
        display: flex;
        gap: 16px;

        input {
          margin-top: 5px;
        }
        label {
          font-size: 18px;
        }
      }
    }

    [aria-invalid='true'] {
      --form-input-border-color: var(--c-red-90);
      --form-input-label-color: var(--c-red-90);
      --form-input-color: var(--c-red-90);
      --form-input-background: var(--c-red-5);

      &:hover {
        --outline-color: var(--form-input-border-color);
      }
    }

    .lp-form-button.primary-button,
    [data-editorblocktype='SubmitButton'] button {
      margin-top: 32px;
      padding: 21px 32px;
      background-color: var(--c-primary);
      color: var(--c-white);
      border: 1px solid var(--c-primary);
      @include transitions(background-color, color);

      line-height: 1;
      letter-spacing: 1.5px;
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;

      &:hover {
        color: var(--c-primary);
        background-color: var(--c-white);
      }
    }

    .textFormFieldBlock,
    .dateTimeFormFieldBlock,
    .twoOptionFormFieldBlock,
    .optionSetFormFieldBlock,
    .multiOptionSetFormFieldBlock,
    .lookupFormFieldBlock {
      gap: 0;
      padding: 0;
    }
  }
}
</style>
